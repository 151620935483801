export class FormHelper {
    //Meta fields used by zendesk that we ignore for the search and ua.
    private ignoreFields = ["ticket_form_id", "description_mimetype"];

    private form: HTMLFormElement;

    constructor(formId: string) {
        this.form = document.querySelector(`form#${formId}`) as HTMLFormElement;
        if (!this.form) {
            throw Error(`Form with id '${formId}' not found in page`);
        }
    }

    public onChange(onChangeHandler: () => void) {
        if (!this.form) return;

        this.form.addEventListener("input", onChangeHandler);

        // Handle Rich text box editor. The textarea input is actually hidden and controlled by a script. The editor we can
        // see in the UI is in an iframe, so we have to observe the changes in that iframe too.
        Array.from(this.form.elements)
            .filter((element) => (element as HTMLInputElement).type == "textarea")
            .forEach((input) => {
                const editorIframe = document.querySelector(`#${input.id}_ifr`) as HTMLIFrameElement;
                if (editorIframe) {
                    // Observe changes within the iFrame's window.
                    const observer = new MutationObserver(onChangeHandler);
                    observer.observe(editorIframe.contentWindow.document, { characterData: true, subtree: true, childList: true });
                }
            })
    }

    public values(): { [key: string]: string } {
        let formData = new FormData(this.form);
        let formValues: { [key: string]: string } = {};
        formData.forEach((value, key) => {
            if (_.isString(value)) {
                let formatedKey = this.formatKey(key);
                if (formatedKey && !this.ignoreFields.includes(formatedKey))
                {
                    if (this.form[key].type === "textarea")
                        formValues[formatedKey] = new DOMParser().parseFromString(value, "text/html").body.innerText;
                    else 
                        formValues[formatedKey] = value;
                }
            }
        });

        return formValues;
    }

    public onSubmit(callback: (event: MouseEvent) => void) {
        let submit = this.form.querySelector(`input[type=submit]`);
        if (submit) submit.addEventListener("click", callback);
    }

    //Formats keys (request[name] -> name)
    private formatKey(key: string): string | undefined {
        var matches = key.match(/\[(.*?)\]/);
        return matches ? matches[1] : undefined;
    }
}
